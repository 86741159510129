import _taggedTemplateLiteral from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _templateObject, _templateObject2;
var __jsx = React.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/** @format */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import ReactTooltip from 'react-tooltip';
import { InfoIcon, ArrowRedRight } from "../_global/Icons";
import { Container } from "../_global";
import { OFFER_TYPES, HOME_PAGE_SECTIONS, featuredMerchantsPropType } from "../../constants/app";
import { colours, responsiveQuery } from "../../constants/css";
import EventTrackingHelper from "../../utils/trackingUtilities";
import NurseryOffer from "../NurseryOffer";
import Slider from 'react-slick';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Link from 'next/link';
import { queries, graphqlGetMcoOffers, graphqlMcoActivate, graphqlBonusCampaignItems } from "../../data/queries";
import { offerProps, offerPropsDefault } from "../CardOffers/mcoOfferProps";
import getMcoForCarousel from "../../utils/getMcoForCarousel";
import { Loading } from "../Category/Offers/style";
import { RightArrow } from "../InfoBox/style";
import { gtagEvent } from "../../utils/gtag";
import config from "../../utils/clientConfig";
import Banner from "../Plugin/Banner";
import { directEarnCurrentOffer } from "../Offer/offerUtils";
import { MultipleCallout } from "../Campaign";
import { mobileAndTabletCheck } from "../../utils/checkMobileTablet";
import OffersCarousel from "../OffersCarousel";
import { TopListWrapper, TopListBox, TopListTitle, McoWrapper, McoHeaderWrapper, BannerText, BonusBar } from "./style";
import { getHowItWorks, calloutSlickSettings } from "./homeUtils";
import CategoryCarousel from "./CategoryCarousel";
import ListItem from "./ListItem";
import Hero from "./Hero";
import BonusBanner from "./BonusBanner";
import CategoryBrandsCarousel from "../CategoryBrandsCarousel";
import { getVisaAmexEnrolmentFlag } from "../../lib/optimizelyFlagUtils";
var handleTooltipClick = function handleTooltipClick(place) {
  switch (place) {
    case HOME_PAGE_SECTIONS.ONLINE_MALL:
      EventTrackingHelper.trackGenericAction('online mall|Icon');
      break;
    case HOME_PAGE_SECTIONS.CARD_OFFERS:
      EventTrackingHelper.trackGenericAction('card offers|Icon');
      break;
    default:
      break;
  }
};
var displayArrow = function displayArrow() {
  return __jsx(React.Fragment, null, __jsx(MediaQuery, {
    query: responsiveQuery.mobileNew
  }, __jsx(ArrowRedRight, {
    className: "arrow-red-right"
  })), __jsx(MediaQuery, {
    query: responsiveQuery.largerThanMobileNew
  }, __jsx(RightArrow, {
    className: "right-arrow"
  })));
};
var displayViewAll = function displayViewAll(place) {
  switch (place) {
    case HOME_PAGE_SECTIONS.ONLINE_MALL:
      return __jsx(Link, {
        href: "/all-stores"
      }, __jsx("a", {
        onClick: function onClick() {
          EventTrackingHelper.trackGenericAction('online mall|View all stores');
        },
        role: "link"
      }, "View all stores", displayArrow()));
    case HOME_PAGE_SECTIONS.CARD_OFFERS:
      return __jsx(Link, {
        href: "/card-offers"
      }, __jsx("a", {
        onClick: function onClick() {
          EventTrackingHelper.trackGenericAction('card offers|View all offers');
        },
        role: "link"
      }, "View all offers", displayArrow()));
    default:
      return __jsx(React.Fragment, null);
  }
};
var renderCallout = function renderCallout(homeContent) {
  return homeContent.calloutSections && homeContent.calloutSections.map(function (callout, i) {
    return __jsx(TopListBox, {
      key: i
    }, __jsx(TopListTitle, null, callout.title), callout.merchants.map(function (seller) {
      if (seller.rebate && seller.rebate.rebate_user) {
        return __jsx(ListItem, {
          src: seller.merchantLogoSquare,
          offerType: OFFER_TYPES.MALL,
          key: seller.merchantName,
          title: seller.merchantName,
          pointsEarn: seller.rebate.rebate_user,
          dollarSpend: "1",
          prevPoints: seller.is_elevated ? seller.was_rebate.rebate_user : 0,
          offerEndDate: seller.rebate.end_date,
          merchantId: seller.merchantId
        });
      } else if (seller.directOffers && seller.directOffers.length > 0) {
        var offer = directEarnCurrentOffer(seller.directOffers);
        return __jsx(ListItem, {
          src: seller.merchantLogoSquare,
          offerType: OFFER_TYPES.DIRECT,
          key: seller.merchantName,
          title: seller.merchantName,
          pointsEarn: offer.points,
          dollarSpend: offer.perValue,
          prevPoints: seller.is_elevated ? seller.was_rebate.rebate_user : 0,
          offerEndDate: offer.endDate,
          merchantId: seller.merchantId,
          bespoke: offer.bespokeOffer
        });
      }
      return null;
    }));
  });
};
var HomeDetail = function HomeDetail(_ref) {
  var bonusCampaignItems = _ref.bonusCampaignItems,
    categories = _ref.categories,
    enrolled = _ref.enrolled,
    ffn = _ref.ffn,
    homeContent = _ref.homeContent,
    mcoLoading = _ref.loading,
    mcoActivate = _ref.mcoActivate,
    originalMcoOffers = _ref.mcoOffers,
    memberStatus = _ref.memberStatus,
    myFavouritesMerchants = _ref.myFavouritesMerchants;
  var isVisaAmexEnabled = getVisaAmexEnrolmentFlag();
  var _useState = useState(false),
    isMobileTablet = _useState[0],
    setIsMobileTablet = _useState[1];

  // ComponentDidMount() clone
  useEffect(function () {
    gtagEvent({
      action: 'conversion',
      tag: config.GTAG.enrolment,
      value: 'qmall/qmallhp+standard'
    });
    if (mobileAndTabletCheck()) {
      setIsMobileTablet(true);
    }
  }, []);
  var _getMcoForCarousel = getMcoForCarousel(originalMcoOffers),
    filteredMco = _getMcoForCarousel.filteredMco,
    activeMcoOffers = _getMcoForCarousel.activeMcoOffers;
  var filteredFeatured = homeContent.featuredMerchants && homeContent.featuredMerchants.filter(function (merchant) {
    return merchant.isVisible;
  });
  var ONLINE_MALL = HOME_PAGE_SECTIONS.ONLINE_MALL,
    CARD_OFFERS = HOME_PAGE_SECTIONS.CARD_OFFERS;
  var favouritesOnBonus = myFavouritesMerchants && myFavouritesMerchants.find(function (merchant) {
    return merchant.is_elevated;
  });
  return __jsx(React.Fragment, null, __jsx(BonusBanner, {
    favouritesOnBonus: favouritesOnBonus
  }), __jsx(Hero, {
    heroes: homeContent.heroCarousel
  }), __jsx(CategoryCarousel, {
    categories: categories
  }), __jsx(Container, {
    wideView: true
  }, __jsx(McoHeaderWrapper, null, __jsx(BannerText, null, __jsx(MediaQuery, {
    query: responsiveQuery.largerThanMobileNew
  }, "Online Mall"), __jsx(MediaQuery, {
    query: responsiveQuery.mobileNew
  }, "Featured brands")), __jsx(MediaQuery, {
    query: responsiveQuery.largerThanMobileNew
  }, Array.isArray(filteredFeatured) && filteredFeatured.length > 0 && displayViewAll(ONLINE_MALL))), __jsx(MediaQuery, {
    query: responsiveQuery.largerThanMobileNew
  }, getHowItWorks('MALL'))), Array.isArray(filteredFeatured) && filteredFeatured.length > 0 && __jsx("div", null, __jsx(MediaQuery, {
    query: responsiveQuery.largerThanMobileNew
  }, __jsx(OffersCarousel, {
    offers: filteredFeatured,
    bonusCampaignItems: bonusCampaignItems,
    offerType: OFFER_TYPES.MALL,
    noMargin: false
  })), __jsx(MediaQuery, {
    query: responsiveQuery.mobileNew
  }, __jsx(OffersCarousel, {
    offers: filteredFeatured,
    bonusCampaignItems: bonusCampaignItems,
    offerType: OFFER_TYPES.MALL,
    noMargin: true
  }), __jsx(CategoryBrandsCarousel, {
    sectionTitle: homeContent.categoryFeaturedMerchants.sectionTitle,
    showIcon: homeContent.categoryFeaturedMerchants.showIcon,
    merchants: homeContent.categoryFeaturedMerchants.merchants,
    categories: categories,
    bonusCampaignItems: bonusCampaignItems
  }))), Array.isArray(filteredMco) && filteredMco.length > 0 && enrolled && memberStatus !== 'OPTED_OUT' && __jsx(McoWrapper, null, __jsx(Container, {
    wideView: true
  }, __jsx(McoHeaderWrapper, null, __jsx(BannerText, null, "Card Offers", __jsx(MediaQuery, {
    query: responsiveQuery.mobileNew
  }, __jsx(InfoIcon, {
    "data-tip": "",
    "data-for": CARD_OFFERS
  }), __jsx(ReactTooltip, {
    event: "click",
    className: "howItWorksToolTip",
    clickable: true,
    offset: {
      right: 15
    },
    id: CARD_OFFERS,
    afterShow: function afterShow() {
      return handleTooltipClick(CARD_OFFERS);
    },
    backgroundColor: colours.qantasTeal
  }, __jsx(Link, {
    href: "/how-it-works#card-offers"
  }, __jsx("a", {
    onClick: function onClick() {
      EventTrackingHelper.trackGenericAction('card offers|How it works');
    },
    role: "link",
    className: "mobileToolTipLink"
  }, "How it works"))))), activeMcoOffers && activeMcoOffers.length > 10 && displayViewAll(CARD_OFFERS)), __jsx(MediaQuery, {
    query: responsiveQuery.largerThanMobileNew
  }, getHowItWorks('MCO'))), mcoLoading ? __jsx(Loading, null) : __jsx(OffersCarousel, {
    offers: filteredMco,
    mcoActivate: mcoActivate,
    ffn: ffn,
    isVisaAmexEnabled: isVisaAmexEnabled,
    offerType: OFFER_TYPES.MCO
  })), !enrolled && __jsx(NurseryOffer, null), homeContent.directCallouts && homeContent.directCallouts.map(function (callout, i) {
    return __jsx(MultipleCallout, {
      key: i,
      merchantCallouts: callout.merchantCallouts,
      title: callout.title
    });
  }), __jsx(Container, {
    wideView: true
  }, __jsx(TopListWrapper, null, __jsx(Slider, calloutSlickSettings, renderCallout(homeContent)))), !isMobileTablet && __jsx(Banner, null));
};
HomeDetail.defaultProps = _objectSpread(_objectSpread({}, offerPropsDefault), {}, {
  authorized: false,
  authenticated: false,
  enrolled: false,
  memberStatus: '',
  bonusCampaignItems: [],
  homeContent: {},
  ffn: ''
});
var mapStateToProps = function mapStateToProps(_ref2) {
  var user = _ref2.user;
  return {
    authorized: user.authorized,
    memberStatus: user.programStatus,
    authenticated: user.authenticated,
    enrolled: user.enrolled,
    ffn: user.ffn,
    myFavouritesMerchants: user.myFavouritesMerchants
  };
};
export default compose(connect(mapStateToProps), graphql(queries.getHome, {
  props: function props(_ref3) {
    var data = _ref3.data;
    return data;
  }
}), graphqlBonusCampaignItems(), graphqlGetMcoOffers(), graphqlMcoActivate(), graphql(gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n      query categoryQuery {\n        categories {\n          categoryId\n          displayName\n          displayOrder\n          hero\n          heroMobile\n          mobileIcon\n          subCategories {\n            categoryId\n            displayOrder\n            displayName\n            parentCategoryId\n          }\n        }\n      }\n    "]))), {
  props: function props(_ref4) {
    var data = _ref4.data;
    return data;
  }
}), graphql(gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      query myFavouritesMerchants {\n        myFavouritesMerchants {\n          merchantId\n          is_elevated\n        }\n      }\n    "]))), {
  skip: function skip(_ref5) {
    var authenticated = _ref5.authenticated,
      authorized = _ref5.authorized;
    return !authenticated && !authorized;
  },
  props: function props(_ref6) {
    var data = _ref6.data;
    return data;
  },
  options: function options() {
    return {
      fetchPolicy: 'no-cache',
      ssr: false
    };
  }
}))(HomeDetail);